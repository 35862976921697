import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    Hi <span role="img" aria-label="Wave">👋🏻</span>, I'm <Styled.a href="https://twitter.com/askar_a">Askar</Styled.a>
    {` `} - Software Developer from Munich
    <br />
    I'm currently working with Typescript/React/NodeJS, learning Elixir and some Ops
  </Fragment>
)
